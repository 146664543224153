<span *ngFor="let item of mypath">
    <span (click)="buildBread(item)" *ngIf="item != mypath[mypath.length-1]">
        <a class="bread">{{ item | uppercase }}</a>&nbsp;&nbsp;&nbsp;<span class="sep">/</span>&nbsp;&nbsp;
    </span>
    
    <span [routerLink]="['/docs/' + path]" *ngIf="item == mypath[mypath.length-1]">
        <a class="bread bread-active">{{ item | uppercase }}</a>
    </span>
</span>

<br><br>

<markdown [data]="content"></markdown>