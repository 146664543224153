import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MarkdownComponent } from './markdown/markdown.component';
import { MarkdownModule } from 'ngx-markdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MarkdownModule.forChild(),       
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        MenuItemComponent,
        MobileMenuComponent,
        MarkdownComponent,
    ],
    entryComponents: [
        HeaderComponent,
        FooterComponent,
        MenuItemComponent,
        MobileMenuComponent,
        MarkdownComponent,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        MenuItemComponent,
        MobileMenuComponent,
        MarkdownComponent,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedComponentsModule {}
