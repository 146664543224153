<div [ngClass]="{ 'nest-sub':path != null, 'nest-top':path == null }">
    <ng-container *ngFor="let key of itemKeys">
        <div class="nest-title pointer" *ngIf="items[key].children" (click)="toggle(key)">
            <i *ngIf="!items[key].open" class="fas fa-plus pr-2"></i>
            <i *ngIf="items[key].open" class="fas fa-minus pr-2"></i>
            {{ key | titlecase }}
        </div>

        <ng-container *ngIf="path && key ? check(path) != key.slice(0,-3) : true">
            <div class="nest-title pointer" 
                    *ngIf="!items[key].children" 
                    (click)="openPageMD(key)"
                    [class.active-link]="activeURL ? replace(activeURL) == key && !clear : null" >

                <i class="fas fa-caret-right"></i>
                {{ chopNumbers(key) | titlecase }}
            </div>
        </ng-container>

        <div class="nest-sub">
            <app-menu-item 
                [items]="items[key].children" 
                [path]="(path ? path + '/' : '' ) + key" 
                [url]="activeURL ? replace(activeURL) == key ? url : 'clear' : null"
                *ngIf="items[key].children && items[key].open">
            </app-menu-item>
        </div>

    </ng-container>
</div>