<div class="mobile-menu">
    <a class="navbar-brand text-light" routerLink="/">
        <img src="/assets/WIRE_Icon_White_min.png" height="30px" class="mr-2">
    </a>

    <form class="w-100" [formGroup]="form" (ngSubmit)="search()">
        <div class="search-bar">
            <input class="form-control" type="email" placeholder="Search" formControlName="searchFor">
            <i class="fas fa-search"></i>
        </div>
    </form>

    <i class="fas fa-bars fa-2x ml-3 white pointer" (click)="openNav()"></i>
</div>
<div id="myNav" class="overlay">
    <i class="fas fa-times fa-2x alacolor closenav" (click)="closeNav()"></i>
    <div class="overlay-content">
        <a class="navbar-brand text-light w-100" routerLink="/">
            <div class="mt-2 d-flex align-items-center justify-content-center w-100">
                <img src="./assets/WIRE_Icon_White_min.png" style="height: 25px; max-width: 400px">
            </div>        
            <div class="w-100 text-center mt-3">
                <h5 class="white m-0">DOCUMENTATION</h5>
            </div>
        </a>
        <form [formGroup]="form" (ngSubmit)="search()">
            <div class="search-bar mt-3">
                <input class="form-control" type="email" placeholder="Search" formControlName="searchFor" >
                <i class="fas fa-search"></i>
            </div>
        </form>
        
        <hr class="sidebar-separator">
        <app-menu-item *ngIf="menu" [items]="menu"></app-menu-item>
    </div>
</div>
