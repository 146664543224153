import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export const httpOptions = {
    headers: new HttpHeaders ({
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer 761e81884cfd1eb12a7ca5ff032b9f7fff09f3bc'
    })
}

@Injectable()
export class GitService {

    MENU;

    constructor(
        private http: HttpClient ) {
            if (!localStorage.getItem('GIT_DOCS')){
                this.getMenuFromGit()
            }
        }

    getMenuFromGit(){
        return new Promise((res) => {
            if (this.MENU) res(this.MENU)
            else {
                let url = "https://api.github.com/repos/siliconswampio/airwire-documentation/contents/";
                this.http.get<any>(url, httpOptions).subscribe((data) =>{
                    console.log(data);
                    
                    let treeUrl = data[0].git_url + '?recursive=1'
                    
                    let temp = {}  
                    this.http.get<any>(treeUrl, httpOptions).subscribe((data) =>{
                        if (data.tree){
                            data.tree.forEach(file => {
                                if (!file.path.includes('resources')){
                                    let pathArr = file.path.split('/');
                                    let fileName = pathArr.pop();
                                    let actingDir = null
                                    
                                    if (pathArr.length > 0){
                                        pathArr.forEach((path)=>{
                                            if (!actingDir) actingDir = temp[path].children
                                            else            actingDir = actingDir[path].children
                                        })
                                    }
                                    else actingDir = temp
                                    
                                    switch(file.type){
                                        case 'tree':
                                            file.children = {}
                                            actingDir[fileName] = file
                                            break;

                                        case 'blob':
                                            actingDir[fileName] = file
                                            break;

                                        default:
                                            //error
                                    }
                                }
                            });
                            console.log(temp);
                            
                            localStorage.setItem('GIT_DOCS', JSON.stringify(temp));
                            this.MENU = temp;
                            res(temp)
                        }
                    })
                })
            }
        })
    }
}