import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

    menu
    form: FormGroup;
    
    constructor (
        private router : Router,
        private formBuilder : FormBuilder ){ 

        this.form = this.formBuilder.group({
            searchFor: ['']
        });
    }
    
    ngOnInit(): void {
        this.menu = JSON.parse(localStorage.getItem('GIT_DOCS'))
        document.getElementById("myNav").style.left = "-100%";
    }

    openNav() {
        document.getElementById("myNav").style.left = "0%";
    }
    
    closeNav() {
        document.getElementById("myNav").style.left = "-100%";
    }

    search(){
        this.closeNav()
        this.router.navigate(['/docs/search'], { queryParams: { for : this.form.value.searchFor } });
    }
}

