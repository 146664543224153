<div class="navbar navbar-default fixed-top">
    <div class="row w-100 d-flex align-items-center justify-content-between">
        <div>
            <a class="navbar-brand text-light" routerLink="/">
                <img src="./assets/WIRE_Icon_White_min.png" height="40px" class="mr-2">
            </a>
        </div>
        <div class="navbar-menu">
            <a class="navbar-menu-item" [routerLink]="['/docs/how eosio works/airwire overview.md']">
                Home
            </a>
            <a class="navbar-menu-item" [routerLink]="['/docs/how eosio works/getting started with eosio/getting started with eosio.md']">
                How It Works
            </a>
            <a class="navbar-menu-item" routerLink="/">
                Getting Started
            </a>
        </div>
    </div>
</div>