import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: ()=> import('./home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'docs',
        loadChildren: ()=> import('./docs/docs.module').then(m => m.DocsModule),
    },
    // {
    //     path: '/docs/search',
    //     loadChildren: ()=> import('./search/search.module').then(m => m.SearchModule),
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
