import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GitService, httpOptions } from 'src/app/_services/git.service';

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss']
})
export class MarkdownComponent implements OnInit, OnChanges {

    @Input()
    path

    temp

    content 

    menu

    mypath

    regPath

    constructor( 
        private git : GitService,
        private http : HttpClient,
        private router : Router ) {}

    ngOnInit(): void {
        this.loadData();
    }

    ngOnChanges(){
        this.loadData()
    }

    loadData(){
        this.menu = JSON.parse(localStorage.getItem('GIT_DOCS'))
        
        if (this.menu){
            let arr = this.path.replace(/_/g, ' ').split('/')
            let file = arr.pop()
            let actingDir = null
                                    
            for (let path of arr){
                if (!actingDir) actingDir = this.menu[path].children
                else            actingDir = actingDir[path].children
            }

            if (actingDir){
                let fileObj = actingDir[file]
                
                if (fileObj){
                    let url = fileObj.url

                    this.http.get(url, httpOptions).toPromise().then((data : any) => {
                        this.content = atob(data.content)
                    })
                }
            }
        }
        this.mypath = this.path.replace(/_/g, ' ').split('/')
    }

    buildBread(item : string){
        let newPath = '/docs/'
        let path = this.path.split('/')

        for (let i = 0; i < path.length; i++){
            if (path[i-1] != item.replace(/ /g, '_'))
                newPath += path[i] + '/'
            else break
        }
        newPath = newPath + item.replace(/ /g, '_') + '.md'
        this.router.navigateByUrl(newPath);
    }
}
