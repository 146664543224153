import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, OnChanges {

    @Input()
    items

    @Input()
    path

    @Input()
    url : string[] | string;

    clear : string

    activeURL : string

    itemKeys : string[] = []

    constructor(
        private router : Router ){}

    ngOnInit(): void {
        this.itemKeys = Object.keys(this.items)
    }

    ngOnChanges(){
        // console.log(this.activeURL);
        
        if (this.url && Array.isArray(this.url) ){
            this.clear = null

            if (!this.itemKeys)
                this.itemKeys = Object.keys(this.items)

            this.activeURL = this.url.shift();
            
            // close all others
            this.itemKeys.forEach((item) => {
                this.items[item].open = false;
            })
            
            if (this.activeURL && this.items.hasOwnProperty(this.replace(this.activeURL)))
                this.items[this.replace(this.activeURL)].open = true

        }
        else if (this.url && this.url == 'clear') {
            this.itemKeys.forEach(key => {
                this.items[key].open = false
            })
        }
    }

    toggle(key : string){
        if (this.items[key].open)
            this.items[key].open = false

        else {
            this.itemKeys.forEach(key => {
                let obj = this.items[key]
                if (obj.children){
                    obj.open = false
                }
            })
            if (this.items[key].hasOwnProperty('open')){
                this.items[key].open = !this.items[key].open;
            }
            else {
                this.items[key].open = true
            }
            Object.keys(this.items[key].children).forEach((newKey) => {
                if (newKey == key + '.md'){
                    this.openPage(key + '/' + newKey)
                }
            })
        }
    }

    openPage(key : string){
        this.activeURL = null;
        let path : string = '/docs/' + (this.path ? this.path + '/' : '' ) + key 
        this.router.navigateByUrl(path.replace(/ /g, '_'))
    }

    openPageMD(key : string){
        document.getElementById("myNav").style.left = "-100%";
        this.activeURL = null;
        let path : string = '/docs/' + (this.path ? this.path + '/' : '' ) + key 
        this.router.navigateByUrl(path.replace(/ /g, '_'))
    }

    replace(content: string) {
        return content.replace(/_/g, " ");
    }

    check(path : string){
        let temp = path.split('/')
        return temp[temp.length-1]
    }

    chopNumbers(str : string){
        return str.slice(str.indexOf('. ')+2, -3)
    }
}
