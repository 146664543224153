import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    currentYear: number = new Date().getFullYear();
    constructor(){}

    reloadCache(){
        localStorage.removeItem('GIT_DOCS');
        location.reload(); 
    }

    open(str : string){
        window.open(str, '_blank')
    }
}
